import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import filterList from 'Actions/lists';
import AreaInput from 'FDComponents/areas/Input';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { parseQuery, stringifyQuery } from 'Services/parseQuery';
import { getProject } from 'Services/project';
import queryReducer from 'Services/queriesReducer';
import debounce, { getLinkId } from 'Services/tools';
import Layout from '../../../common/components/Layout';
import { SearchFilter } from '../../../common/components/SearchFilter';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../common/components/TableList';
import { UserRestriction } from '../../../common/components/UserRestiction';
import { addChannel, fetchChannelsList } from '../actions';
import ChannelDialog from '../Dialog';
import Row from './Row';
import { Alert } from "@material-ui/lab";

@injectIntl
@connect((store, props) => ({
  channels: store.channels.list,
  fetching: store.channels.fetching,
  pagination: store.channels.pagination,
  total: store.channels.totalItems,
  query: parseQuery(props.location.search),
}))
export default class Channels extends Component {
  config = {
    filters: [
      {
        type: 'name',
        labelKey: 'filter_name',
      },
      {
        type: 'area',
        input: AreaInput,
        labelKey: 'filter_country'
      },
    ],
    headers: [
        { id: 'name', labelKey: 'field_name' },
        { labelKey: 'field_logo' },
        { labelKey: 'field_parent' },
        { id: 'area.translations.name', labelKey: 'field_area' },
        { labelKey: 'field_isActive' }],
  };

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;
    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchChannelsList(filter));
    dispatch(filterList('channels', filter));
  };

  handleCreate = channel => {
    const { dispatch, history } = this.props;
    dispatch(addChannel(channel)).then(({ payload }) => {
      if (payload && payload.id) {
        history.push(`${getProject().url}/channels/${payload.id}`);
      }
    });
  };

  handleFilters = change => {
    const { name , area } = change;
    const filtersQuery = {
      name,
      area: getLinkId(area),
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };

  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };
  handleSort = field => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };
  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search != search) {
      history.push(`${getProject().url}/channels${search}`);
    }
  }, 500);

  render() {
    const {
      channels,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="channels-layout"
        top={<SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_channels' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_channelsList}</Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <ChannelDialog
                  title={m.dialog_addChannel}
                  form="channel_add"
                  onCreate={this.handleCreate}
                >
                  <Button variant="contained" color="primary">
                    <AddIcon style={{ marginRight: 8 }} />
                    {m.button_add}
                  </Button>
                </ChannelDialog>
              </UserRestriction>
            </Toolbar>
            <Alert severity="info">
              <a href={`#${getProject().url}/documentation/broadcasts_channels`} target="_blank">
                {m.read_the_docs}
              </a>
            </Alert>
            <SearchFilter
              form="channels_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={channels}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={Row}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }
}
