import {Paper, Toolbar, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import Layout from "../../../common/components/Layout";
import {SearchFilter} from "../../../common/components/SearchFilter";
import {SimpleBreadcrumbs} from "../../../common/components/SimpleBreadcrumbs";
import {TableList} from "../../../common/components/TableList";
import ToggleFilter from "../../../common/components/ToggleFilter";
import {UserRestriction} from "../../../common/components/UserRestiction";
import {stringifyQuery} from "../../../common/services/parseQuery";
import {getProject} from "../../../common/services/project";
import queryReducer from "../../../common/services/queriesReducer";
import debounce, {getLinkId} from "../../../common/services/tools";
import CompetitionInput from "../../competitions/Input";
import MatchInput from "../../matches/Input";
import PersonInput from "../../people/Input";
import TeamInput from "../../teams/Input";
import QuizzDialog from "../QuizzDialog";
import {IQuizz} from "../typings";
import { IComponentProps } from './QuizzesList.connector';
import {QuizRow} from "./QuizzRow.component";
import {Alert} from "@material-ui/lab";
export class QuizzesList extends React.Component<IComponentProps> {

    private config = {
        filters: [
            {
                type: 'surveyId',
                labelKey: 'field_id',
            },
            {
                type: 'surveyCompetition',
                input: CompetitionInput,
                labelKey: 'filter_competition',
            },
            {
                type: 'surveyTeam',
                input: TeamInput,
                labelKey: 'filter_team',
            },
            {
                type: 'surveyMatch',
                input: MatchInput,
                labelKey: 'filter_match',
            },
            {
                type: 'surveyPerson',
                input: PersonInput,
                labelKey: 'filter_person',
            },
        ],
        toggles: [
            {
                type: 'surveyCurrentLocaleOnly',
                labelKey: 'field_currentLocaleOnly',
            },
        ],
        headers: [
            { labelKey: 'field_title' },
            { labelKey: 'field_isAvailable' },
            { labelKey: 'field_isEnabled' },
            { labelKey: 'field_startDate' },
            { labelKey: 'field_endDate' },
            { labelKey: 'field_translations' },
            { labelKey: 'field_hasPartner' },
            { labelKey: 'field_totalParticipantsCount' },
            { labelKey: 'field_translate' },
        ],
    };

    public componentDidMount() {
        const { location } = this.props;
        this.fetchData(location.search);
    }

    public componentDidUpdate(prevProps: IComponentProps) {
        const { location } = this.props;
        if (prevProps.location.search !== location.search) {
            this.fetchData(location.search);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps) {
        const { location: oldLocation } = this.props;
        const { location } = nextProps;

        if (oldLocation.search !== location.search) {
            this.fetchData(location.search);
        }
    }

    private changeParams = debounce(action => {
        const { history, query, location } = this.props;
        const newParams = queryReducer(query, action);
        const search = stringifyQuery(newParams);
        if (location.search !== search) {
            history.push(`${getProject().url}/quizzes${search}`);
        }
    }, 500);

    private fetchData = (filter: string) => {
        const { fetchQuizzes } = this.props;
        fetchQuizzes(filter);
    };

    private handlePagination = (change: any) => {
        if (change.page) {
            this.changeParams({ type: 'SET_PAGE', payload: change.page });
        } else {
            this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
        }
    };

    private handleSort = (field: any) => {
        this.changeParams({ type: 'SET_SORT', payload: field });
    };

    public render() {
        const {
            quizzes,
            fetching,
            pagination,
            total,
            query,
            intl: { messages: m },
        } = this.props;

        return (
            <Layout
                id="quizzes-layout"
                top={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_quizz' }]} />
                    </div>
                }
                main={
                    <Paper elevation={2}>
                        <Toolbar>
                            <Typography variant="h6">{m.app_quizz}</Typography>
                            <div style={{ flex: 1 }} />
                            <UserRestriction>
                                <QuizzDialog title={m.dialog_addQuizz} form="quizz_add" onCreate={this.submitAdd}>
                                    <Button variant="contained" color="primary">
                                        <AddIcon style={{ marginRight: 8 }} />
                                        {m.button_add}
                                    </Button>
                                </QuizzDialog>
                            </UserRestriction>
                        </Toolbar>
                        <Alert severity="info">
                            <a href={`#${getProject().url}/documentation/quizs`} target="_blank">
                                {m.read_the_docs}
                            </a>
                        </Alert>
                        <ToggleFilter
                            form="surveys_togglefilters"
                            toggles={this.config.toggles}
                            onChange={this.handleToggles}
                            initialValues={query.toggles}
                        />
                        <SearchFilter
                            form="surveys_filters"
                            filters={this.config.filters}
                            onChange={this.handleFilters}
                            initialValues={query.filters}
                        />
                        <TableList
                            tableData={quizzes}
                            tableHeaders={this.config.headers}
                            query={query}
                            onSort={this.handleSort}
                            Row={QuizRow}
                            loading={fetching}
                            pagination={pagination}
                            total={total}
                            onPaginate={this.handlePagination}
                        />
                    </Paper>
                }
            />
        );
    }

    private handleFilters = change => {
        const { quizzTeam, quizzCompetition, quizzMatch, quizzPerson, quizzId } = change;
        const filtersQuery = {
            quizzTeam: getLinkId(quizzTeam),
            quizzCompetition: getLinkId(quizzCompetition),
            quizzMatch: getLinkId(quizzMatch),
            quizzPerson: getLinkId(quizzPerson),
            quizzId,
        };
        this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
    };
    private handleToggles = change => {
        this.changeParams({ type: 'SET_TOGGLE', payload: change });
    };

    private submitAdd = (quizz: Partial<IQuizz>) => {
        const { addQuizz, history } = this.props;
        quizz.questions = [{ question: '', ordering: 1, index : 1, options: [{ label: '' , value :1 , isRightAnswer: true}] }];

        // @ts-ignore
        addQuizz(quizz).then(({ payload }) => {
            if (payload && payload.id) {
                history.push(`${getProject().url}/quizzes/${payload.id}`);
            }
        });
    };
}
