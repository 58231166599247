import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ContentAdd from '@material-ui/icons/Add';
import ConstantDialog from 'FDComponents/constants/Dialog';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { UserRestriction } from '../../../common/components/UserRestiction';
import ContractsPanels from '../../contracts/Panels';
import { Alert } from "@material-ui/lab";
import { getProject } from "../../../common/services/project";

const styles = {
  icon: {
    marginRight: 8,
  },
};

@withStyles(styles)
@injectIntl
export default class Contracts extends Component {
  render() {
    const {
      contracts,
      classes,
      onAdd,
      intl: { messages: m },
    } = this.props;
    return (
      <div>
        <Toolbar>
          <Typography variant="h6">{m.app_contracts}</Typography>
          <div style={{ flex: 1 }} />
          <UserRestriction>
            <ConstantDialog
              title={m.dialog_addContract}
              form="contract_add"
              onCreate={onAdd}
              entity="contract"
            >
              <Button variant="contained" color="primary">
                <ContentAdd className={classes.icon} />
                {m.button_add}
              </Button>
            </ConstantDialog>
          </UserRestriction>
        </Toolbar>
        <Alert severity="info">
          <a href={`#${getProject().url}/documentation/contracts`} target="_blank">
            {m.read_the_docs}
          </a>
        </Alert>
        <div style={{ padding: 16 }}>
          <ContractsPanels contracts={contracts} />
        </div>
      </div>
    );
  }
}
